import { UserAuth } from "context/AuthContext";
import { sendEmailVerification } from "firebase/auth";
import React, { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const VerifyEmail = () => {
  const { user } = UserAuth();
  const [emailSent, setEmailSent] = useState(false);
  const referrer = new URLSearchParams(useLocation().search).get("referrer");
  const referrerParam = referrer ? `?referrer=${referrer}` : ``;
  const continueURL = `https://app.getnitenite.com/` + referrerParam;

  useEffect(() => {
    try {
      sendEmailVerification(user, { url: continueURL });
      setEmailSent(true);
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <Container fluid className="h-screen text-center">
      <img src={process.env.PUBLIC_URL + "icons/logo.png"} alt="nite nite" className="w-[20vmin] mx-auto" />
      <h1 className="mb-5 mt-10">Verify your email</h1>
      <p>We send you an email to {user.email}</p>
      <p>Click the link inside to get started.</p>

      <p className="font-semibold">Please check your spam folder. The email may take up to 5 minutes to arrive.</p>
    </Container>
  );
};

export default VerifyEmail;
