import React from "react";
import { LibraryIcon, SingleStoryIcon } from "./icons";

const SavedStories = ({ loadedStories, showStory, setCurrentPage }) => {
  return (
    <div className="flex flex-col py-6 pb-20 px-4 mx-1 align-middle">
      <div className="mt-2 mb-2" style={{ display: "grid", gridTemplateColumns: `32px 1fr`, gap: "12px" }}>
        <LibraryIcon size={32}></LibraryIcon>
        <h1 className="text-xl font-bold w-full">Library</h1>
      </div>

      <div className="max-w-md">
        {loadedStories.length === 0 ? (
          <>
            <p>Looks empty over here, start creating some stories!</p>
            <a
              onClick={(e) => {
                e.preventDefault();
                setCurrentPage("create");
              }}
              className="cursor-pointer"
            >
              Create one here
            </a>
          </>
        ) : (
          loadedStories.map((story, index) => {
            let moral = story.data.promptMoral.substring(0, 1).toUpperCase() + story.data.promptMoral.substring(1, 50);
            const titleRegex = new RegExp(/\[(.*?)\]/, "i");
            const title = story.data.story.match(titleRegex)
              ? story.data.story.match(titleRegex)[0]
              : moral;
            return (
              <a
                onClick={(e) => showStory(e, index)}
                key={story.id}
                className="!text-black grid gap-2 mr-2 cursor-pointer no-underline my-3 border-2 border-x-transparent border-t-transparent justify-start items-center py-1 hover:border-b-slate-400"
                style={{ gridTemplateColumns: "24px minmax(0, 1fr) minmax(0, 1fr)" }}
              >
                <SingleStoryIcon size={24} />
                <h2 className="m-0 text-base whitespace-nowrap overflow-hidden">{title.replace("[", "").replace("]", "")}</h2>
                <p className="m-0 text-sm opacity-75 whitespace-nowrap overflow-hidden">{moral}</p>
              </a>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SavedStories;
