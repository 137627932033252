import { createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [userLoading, setUserLoading] = useState(true);
  
  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  const logout = () => {
    
    return signOut(auth);
  };

  useEffect(() => {
    let onsub = onAuthStateChanged(auth, (currentUser) => {
      setUserLoading(false)
      setUser(currentUser);
    });
    return () => {
      onsub();
    };
  }, []);

  return <UserContext.Provider value={{ createUser, login, logout, user, userLoading }}>{children}</UserContext.Provider>;
};

export const UserAuth = () => {
  return useContext(UserContext);
};
