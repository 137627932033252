import { connectFirestoreEmulator } from "firebase/firestore";
import React from "react";
import { UserAuth } from "../context/AuthContext";
import SplashScreen from "./App/SplashScreen";
import Landing from "./Landing";
import Main from "./Main";
import VerifyEmail from "./VerifyEmail";

const Home = () => {
  const { user, userLoading } = UserAuth();
  return (
    <>
      {userLoading ? <SplashScreen /> : !!user ? user.emailVerified ? <Main></Main> : <VerifyEmail /> : <Landing></Landing>}
    </>
  );
};


export default Home;
