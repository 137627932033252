import React from "react";
import { HowToIcon } from "./icons";

const HowTo = () => {
  return (
    <div className="min-h-full flex flex-col py-6 pb-20 px-4 mx-1 align-middle">
      <div className="mt-2" style={{ display: "grid", gridTemplateColumns: `32px 1fr`, gap: "12px" }}>
        <HowToIcon size={32}></HowToIcon>
        <h1 className="text-xl font-bold w-full mb-3">How to Create a Story</h1>
      </div>
      <div>
        <p className="mt-3 font-semibold">We've got two options for you:</p>
        <p>
          You can read through our step-by-step instructions to get the most out of NiteNite, or sit back, relax, and
          let our amazing video, made with the help of cutting-edge A.I. technology, take you on a journey through all
          the unique capabilities of this innovative product.
        </p>
        <video className="my-3 max-w-full w-56 mx-auto" controls>
          <source src={process.env.PUBLIC_URL + `/media/NiteNite_-_Avatar.mp4`} type="video/mp4" />
        </video>
        <p>
          Hi, I'm Julia, Chief Mom Officer here at NiteNite. First, thank you for creating your account. We are thrilled
          to help you raise smarter and happier kids through the power of stories.
        </p>
        <p>
          Today I want to let you know how to make the most out of the powerful technology behind NiteNite to generate
          engaging and educational stories in seconds.
        </p>
        <p className="font-semibold">Better Inputs = Better Stories</p>
        <p>
          <span className="font-medium">1. Lesson you want to teach - </span>The more precise you are here, the better.
          You will get the best results when you give NiteNite the lesson you want to teach and also the benefit of that
          lesson or specific action, for example: eating breakfast will give you energy, brushing your teeth will avoid
          cavities and keep you away from the dentist the more effort you put in, the more rewards you get.
        </p>
        <p>
          <span className="font-medium">2. Main character - </span>Here, you can pick any character your children like;
          This will help generate rapport between the story and your kid. We recommend choosing animals or fantasy
          characters like dragons or unicorns.
        </p>
        <p>
          <span className="font-medium">3. Style - </span>Here, you can input any book author you like. It can be
          children or adult authors. You may want to model the style of a book series your children love. If you can't
          think of anything, don't worry, leave that blank, and we will take care of the rest.
        </p>
        <p>
          <span className="font-medium">4. Kids age - </span>Just input your kid's age in number.
        </p>
        <p className="mt-6 font-semibold">A New Story Every Time</p>
        <p>
          Kids, and everyone for that matter, learn better through repetition. We recommend creating and telling your
          kids a few stories with the same lesson. Changing the character and style will help to keep things
          interesting. NiteNite will create a different version each time. 
        </p>
        <p>Finally, if you have any questions or
          suggestions, please email us at <i className="font-medium">hello@getnitenite.com</i>. We are happy to chat anytime.</p>
      </div>
    </div>
  );
};

export default HowTo;
