import { UserAuth } from "context/AuthContext";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import ShareOnSocial from "react-share-on-social";
import "../../styles/cardanim.css";

const SocialShare = ({ isVisible, setshowSocialShare }) => {
  const { user } = UserAuth();
  return (
    <Modal
      show={isVisible}
      onHide={() => setshowSocialShare(false)}
      dialogClassName="!max-w-xl anim-card h-full pt-[64px]"
      contentClassName=""
    >
      <Modal.Dialog className="h-full justify-center m-0 !max-w-xl" contentClassName="h-full !border-none">
        <Modal.Header className="!border-none mt-1 mx-2" closeButton></Modal.Header>

        <div className="grid gap-3 justify-center items-center content-evenly px-4 mb-12">
          <p className="text-base font-semibold px-3 m-0 mb-3 text-center">
            Invite your friends and family and get 5 new stories for every new sign-up.
          </p>
          <input
            type="text"
            // @ts-ignore
            onClick={(e) => e.target.select()}
            className="text-xs m-0 border p-1"
            value={`https://app.getnitenite.com/?referrer=${user.uid}`}
          />
          <ShareOnSocial
            textToShare="Sign up now and experience AI generated stories for your kids!"
            link={`https://app.getnitenite.com/?referrer=${user.uid}`}
            linkTitle="Get free credits"
            linkMetaDesc="Invite your friend and family and get 5 new stories for every new sign-up."
            linkFavicon={process.env.PUBLIC_URL + "favicon.ico"}
            shareTo={["facebook", "twitter", "whatsapp", "reddit", "linkedin", "pinterest", "telegram"]}
            noReferer
          >
            <div className="cursor-pointer">
              <Button size="lg" className="!bg-[#3C01E6] !font-semibold !border-none !text-lg hover:opacity-70 w-full">
                Share
              </Button>
            </div>
          </ShareOnSocial>
          <hr className="my-0"></hr>
          <div className="">
            <img
              className="h-[64px] inline-block mr-2"
              src={process.env.PUBLIC_URL + "icons/tiktok-icon-white-1.svg"}
              alt="tiktok icon"
            />
            <img
              className="h-[64px] inline-block"
              src={process.env.PUBLIC_URL + "icons/instagram-1.svg"}
              alt="tiktok icon"
            />
          </div>
          <p className="text-xs mb-0">
            Share a TikTok or Instagram Reel about NiteNite and get 5 more free stories. Send the link to your post to
            hello@getnitenite.com to get the credits.
          </p>
        </div>
      </Modal.Dialog>
    </Modal>
  );
};

export default SocialShare;
