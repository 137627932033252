import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import Login from "./Login";
import Signup from "./Signup";

const Landing = () => {
  const [loginVisible, setloginVisible] = useState(false);
  const [signupVisible, setsignupVisible] = useState(true);
  const [welcomeVisible, setWelcomeVisible] = useState(false);
  function showLogin(e) {
    e.preventDefault();
    setloginVisible(true);
    setsignupVisible(false);
    setWelcomeVisible(false);
  }
  function showSignup(e) {
    e.preventDefault();
    setsignupVisible(true);
    setloginVisible(false);
    setWelcomeVisible(false);
  }
  return (
    <Container fluid className="h-screen">
      <img src={process.env.PUBLIC_URL + "icons/logo.png"} alt="nite nite" className="w-[60px] mx-auto mt-6 mb-4" />
      <CSSTransition in={signupVisible} timeout={300} classNames="form" unmountOnExit>
        <Signup showLogin={showLogin}></Signup>
      </CSSTransition>
      <CSSTransition in={loginVisible} timeout={300} classNames="form" unmountOnExit>
        <Login showSignup={showSignup}></Login>
      </CSSTransition>

      {welcomeVisible && (
        <div className="max-w-lg mx-auto py-1 text-center my-12 flex flex-col">
          <div className="flex flex-col justify-center my-auto">
            <p className="mb-10 text-xl">
                Please log in or sign up to continue
              </p>
            <div className="flex flex-col justify-center gap-y-6 w-3/6 mx-auto">
              <Button
                size="lg"
                className="w-full"
                onClick={(e) => {
                  showLogin(e);
                }}
              >
                Log in
              </Button>
              <Button
                size="lg"
                className="w-full"
                onClick={(e) => {
                  showSignup(e);
                }}
              >
                Sign up
              </Button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Landing;
