import { addDoc, collection, onSnapshot } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { db } from "../../firebase";
import "../../styles/cardanim.css";
import { Spinner } from "./Generating";

const StripeProducts = ({ isVisible, setShowProducts, products, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleCreateCheckout = async (priceID, productID) => {
    setIsLoading(true);
    try {
      const docRef = await addDoc(collection(db, "stripe_customers", user.uid, "checkout_sessions"), {
        mode: "payment",
        price: priceID, // One-time price created in Stripe
        automatic_tax : true,
        product: productID,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
      const onCheckoutSession = onSnapshot(docRef, (snap) => {
        if (snap.data().error) {
          console.log(snap.data().error);
          setIsLoading(false);
          onCheckoutSession();
          return;
        }
        if (snap.data().url) {
          onCheckoutSession();
          window.open(snap.data().url, "_self");
          setIsLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={isVisible}
      onHide={() => setShowProducts(false)}
      dialogClassName="!max-w-xl anim-card h-full pt-[64px]"
      contentClassName=""
    >
      <Modal.Dialog className="h-full justify-center m-0 !max-w-xl" contentClassName="h-full !border-none">
        <Modal.Header className="!border-none mt-1 mx-2" closeButton></Modal.Header>

        <div
          className="grid gap-8 justify-center items-center content-evenly px-4 mb-12"
          style={!isLoading ? { gridTemplateRows: `repeat(${products.length + 1}, minmax(0, 1fr))` } : {}}
        >
          <h1 className="text-xl px-3 m-0 text-center">Create More Fantastic Stories</h1>
          {isLoading ? (
            <div className=" w-full max-w-lg">
              <Spinner />
              <p>Your order is loading. Please wait.</p>
            </div>
          ) : (
            products.map((product) => {
              return (
                <div key={product.id} className="grid grid-cols-2 gap-5 w-full max-w-lg">
                  <div className="w-full text-center">
                    <p className="m-0 text-sm font-semibold">{product.name}</p>
                    <p className="m-0 text-xs">{product.description}</p>
                  </div>
                  <Button
                    className="px-1 my-auto !bg-[#3C01E6] !font-medium !border-none !text-sm hover:opacity-70"
                    onClick={() => handleCreateCheckout(product.price.id, product.id)}
                  >
                    ${product.price.unit_amount / 100} USD
                  </Button>
                </div>
              );
            })
          )}
        </div>
      </Modal.Dialog>
    </Modal>
  );
};

export default StripeProducts;
