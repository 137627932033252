import React from "react";

const Wrapper = ({ children, width, height }) => {
  return (
    <svg width={width || "26"} height={height || "26"} fill="currentColor" viewBox="0 0 48 48">
      {children}
    </svg>
  );
};

const AccountIcon = (props) => {
  return (
    <Wrapper width={props?.size} height={props?.size}>
      <path d="M11.1 35.25q3.15-2.2 6.25-3.375Q20.45 30.7 24 30.7q3.55 0 6.675 1.175t6.275 3.375q2.2-2.7 3.125-5.45Q41 27.05 41 24q0-7.25-4.875-12.125T24 7q-7.25 0-12.125 4.875T7 24q0 3.05.95 5.8t3.15 5.45ZM24 25.5q-2.9 0-4.875-1.975T17.15 18.65q0-2.9 1.975-4.875T24 11.8q2.9 0 4.875 1.975t1.975 4.875q0 2.9-1.975 4.875T24 25.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.775t4.3-6.35q2.725-2.725 6.375-4.3Q19.9 4 24 4q4.15 0 7.775 1.575t6.35 4.3q2.725 2.725 4.3 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.3 6.375-2.725 2.725-6.35 4.3Q28.15 44 24 44Zm0-3q2.75 0 5.375-.8t5.175-2.8q-2.55-1.8-5.2-2.75-2.65-.95-5.35-.95-2.7 0-5.35.95-2.65.95-5.2 2.75 2.55 2 5.175 2.8Q21.25 41 24 41Zm0-18.5q1.7 0 2.775-1.075t1.075-2.775q0-1.7-1.075-2.775T24 14.8q-1.7 0-2.775 1.075T20.15 18.65q0 1.7 1.075 2.775T24 22.5Zm0-3.85Zm0 18.7Z" />
    </Wrapper>
  );
};

const CreateIcon = (props) => {
  return (
    <Wrapper width={props?.size} height={props?.size}>
      <path d="M16.05 40q-1.2 0-2.1-.9-.9-.9-.9-2.1v-5h6.2v-6.35q-1.9.15-3.8-.525T12.05 23v-2.9h-2.5L3 13.55q1.8-1.7 4.3-2.775T12.4 9.7q1.5 0 3.425.475 1.925.475 3.425 1.525V8H42v26.75q0 2.2-1.525 3.725T36.75 40Zm6.2-8H34.5v2.75q0 1 .625 1.625T36.75 37q1 0 1.625-.625T39 34.75V11H22.25v2.85L34.3 25.9v2.15h-2.15l-6.3-6.3-.85 1q-.65.75-1.3 1.15-.65.4-1.45.75ZM10.9 17.1h4.15v4.45q.85.55 1.675.825.825.275 1.675.275 1.25 0 2.55-.675 1.3-.675 1.9-1.375l.85-1-3.45-3.45q-1.6-1.6-3.6-2.525-2-.925-4.25-.925-1.35 0-2.45.325T7.7 13.9ZM31.5 35H16.05v2H32.2q-.3-.3-.5-.825-.2-.525-.2-1.175Zm-15.45 2v-2 2Z" />
    </Wrapper>
  );
};
const LibraryIcon = (props) => {
  return (
    <Wrapper width={props?.size} height={props?.size}>
      <path d="M17.15 27H28.4v-3H17.15Zm0-4.5H36.9v-3H17.15Zm0-4.5H36.9v-3H17.15ZM13 38q-1.2 0-2.1-.9-.9-.9-.9-2.1V7q0-1.2.9-2.1.9-.9 2.1-.9h28q1.2 0 2.1.9.9.9.9 2.1v28q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h28V7H13v28Zm-6 9q-1.2 0-2.1-.9Q4 42.2 4 41V10h3v31h31v3Zm6-37v28V7Z" />
    </Wrapper>
  );
};
const HowToIcon = (props) => {
  return (
    <Wrapper width={props?.size} height={props?.size}>
      <path d="M12 44v-8.6q-2.85-2.6-4.425-6.075Q6 25.85 6 22q0-7.5 5.25-12.75T24 4q6.25 0 11.075 3.675Q39.9 11.35 41.35 17.25l2.75 10.9q.2.7-.25 1.275t-1.2.575H38v7q0 1.25-.875 2.125T35 40h-5v4h-3v-7h8V27h5.7l-2.25-9q-1.2-4.85-5.25-7.925T24 7q-6.25 0-10.625 4.325T9 21.9q0 3.2 1.325 6.1 1.325 2.9 3.725 5.15l.95.9V44Zm12.85-18.5ZM24 32q.85 0 1.425-.575Q26 30.85 26 30q0-.85-.575-1.425Q24.85 28 24 28q-.85 0-1.425.575Q22 29.15 22 30q0 .85.575 1.425Q23.15 32 24 32Zm-1.5-6.4h3.05q0-1.25.325-2.025Q26.2 22.8 27.2 21.7q.9-1 1.75-2.025T29.8 17q0-2.1-1.625-3.55Q26.55 12 24.15 12q-2 0-3.625 1.15t-2.275 3L21 17.3q.35-1.1 1.225-1.775.875-.675 1.925-.675 1.1 0 1.825.6.725.6.725 1.55 0 1.05-.625 1.875T24.6 20.55q-1 1.05-1.55 2.1t-.55 2.95Z" />
    </Wrapper>
  );
};

const SingleStoryIcon = (props) => {
    return (
      <Wrapper width={props?.size} height={props?.size}>
        <path d="M15.95 35.5h16.1v-3h-16.1Zm0-8.5h16.1v-3h-16.1ZM11 44q-1.2 0-2.1-.9Q8 42.2 8 41V7q0-1.2.9-2.1Q9.8 4 11 4h18.05L40 14.95V41q0 1.2-.9 2.1-.9.9-2.1.9Zm16.55-27.7V7H11v34h26V16.3ZM11 7v9.3V7v34V7Z"/>
      </Wrapper>
    );
  };

export { AccountIcon, CreateIcon, LibraryIcon, HowToIcon, SingleStoryIcon };
