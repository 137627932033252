import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import Account from "./App/Account";
import Create from "./App/Create";
import SavedStories from "./App/SavedStories";
import "../styles/scrollbar.css";
import "../styles/nav-activeButtons.css";
import NavigationButtons from "./App/NavigationButtons";
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { db, analytics } from "../firebase";
import SingleStoryCard from "./App/SingleStoryCard";
import StripeProducts from "./App/StripeProducts";
import HowTo from "./App/HowTo";
import TopBanner from "./App/TopBanner";
import { useLocation } from "react-router-dom";
import SplashScreen from "./App/SplashScreen";
import SocialShare from "./App/SocialShare";
import { logEvent } from "firebase/analytics";

const Main = () => {
  const { user } = UserAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState("create");
  const [loadedStories, setLoadedStories] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);
  const [isCreatingStory, setIsCreatingStory] = useState(false);
  const [credits, setCredits] = useState(0);
  const [showStripeProducts, setShowStripeProducts] = useState(false);
  const [showSocialShare, setshowSocialShare] = useState(false);
  const [products, setProducts] = useState([]);
  const referrer = new URLSearchParams(useLocation().search).get("referrer");

  const collectionPath = collection(db, "users", `${user.uid}`, "stories");

  const queryPath = query(collectionPath, where("generatedAt", ">", 0), orderBy("generatedAt", "desc"), limit(10));

  const showStory = (e, index) => {
    e.preventDefault();
    setSelectedStory(loadedStories[index]);
  };

  const pages = [
    {
      id: "create",
      component: (
        <Create
          showProducts={setShowStripeProducts}
          credits={credits}
          setSelectedStory={setSelectedStory}
          collectionPath={collectionPath}
          isCreatingStory={isCreatingStory}
          setIsCreatingStory={setIsCreatingStory}
        ></Create>
      ),
    },
    {
      id: "library",
      component: (
        <SavedStories
          loadedStories={loadedStories}
          showStory={showStory}
          setCurrentPage={setCurrentPage}
        ></SavedStories>
      ),
    },
    {
      id: "howto",
      component: <HowTo></HowTo>,
    },
    {
      id: "account",
      component: <Account credits={credits} showProducts={setShowStripeProducts}></Account>,
    },
  ];

  const currentPageIndex = () => {
    return pages.findIndex((page) => page.id === currentPage);
  };

  useEffect(() => {
    logEvent(analytics, "login")
    const unsubSavedStories = onSnapshot(queryPath, (snapshot) => {
      const storiesArray = [];
      snapshot.forEach((story) => {
        storiesArray.push({ id: story.id, data: story.data() });
      });
      setLoadedStories(storiesArray);
    });
    const unsubCredits = onSnapshot(doc(db, "credits", user.uid), (doc) => {
      if (doc.exists()) {
        setCredits(doc.data().credits);
        setIsLoading(false);
      }
    });

    getDoc(doc(db, "users", user.uid)).then(async function (doc) {
      if (doc.exists()) {
        if (doc.data().verified === true) {
          return;
        }
      }
      if (referrer === user.uid) {
        return;
      }
      await setDoc(
        doc.ref,
        {
          verified: true,
          referrer: referrer,
        },
        { merge: true }
      );
    });

    let tempProducts = [];
    getDocs(query(collection(db, "stripe_products"), where("active", "==", true))).then(function (querySnapshot) {
      querySnapshot.forEach(async function (doc) {
        let data = doc.data();
        let product = {
          id: doc.id,
          name: data.name,
          description: data.description,
          images: data.images,
        };
        // const priceSnap = await doc.ref.collection('prices').get();
        const priceSnap = await getDocs(collection(db, doc.ref.path, "prices"));

        if (priceSnap.docs[0].exists()) {
          const priceData = priceSnap.docs[0];
          product.price = {
            id: priceData.id,
            product: priceData.data().product,
            unit_amount: priceData.data().unit_amount,
          };
        }
        tempProducts.push(product);
      });
    });
    setProducts(tempProducts);

    return () => {
      unsubSavedStories();
      unsubCredits();
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <div className="overflow-x-hidden overflow-y-auto h-screen relative select-none">
          <TopBanner onButtonClick={setshowSocialShare}></TopBanner>

          <div
            className={` grid grid-cols-4 grid-rows-1 content-baseline transition-transform  overflow-y-hidden box-content h-[calc(100%-96px-64px)]`}
            style={{
              width: `calc(100% * ${pages.length})`,
              transform: `translateX(-${(100 / pages.length) * currentPageIndex()}%)`,
              height: "calc(100% - 96px - 64px)",
              marginTop: "64px",
            }}
          >
            {pages.map((page) => {
              return (
                <div key={page.id} className="w-full mx-auto  overflow-y-scroll relative">
                  <div className="max-w-3xl w-full mx-auto shadow-md min-h-full flex flex-col">{page.component}</div>
                </div>
              );
            })}
          </div>
          {selectedStory && (
            <SingleStoryCard selectedStory={selectedStory} setSelectedStory={setSelectedStory}></SingleStoryCard>
          )}
          <SocialShare isVisible={showSocialShare} setshowSocialShare={setshowSocialShare}></SocialShare>

          <StripeProducts
            isVisible={showStripeProducts}
            setShowProducts={setShowStripeProducts}
            products={products}
            user={user}
          ></StripeProducts>

          <NavigationButtons
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setSelectedStory={setSelectedStory}
            showProducts={setShowStripeProducts}
          ></NavigationButtons>
        </div>
      )}
    </>
  );
};

export default Main;
