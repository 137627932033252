import React from "react";
import { Button } from "react-bootstrap";

const TopBanner = ({ onButtonClick }) => {
  return (
    <div className="top-0 fixed w-full h-16 z-10" style={{ backgroundColor: "#3C01E6" }}>
      <img
        className="!h-full py-2 absolute mx-auto my-auto inset-0"
        src={process.env.PUBLIC_URL + "/icons/logo 2.png"}
        alt=""
      />
      <div className="mx-auto max-w-2xl h-full grid content-center justify-end">
        <Button onClick={ () => onButtonClick(true)} size="sm" className="!bg-[#FFB800] !text-[#3C01E6] !border-none max-w-[33vw] mr-2">
          Free Credits
        </Button>
      </div>
    </div>
  );
};

export default TopBanner;
