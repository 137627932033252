// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth"
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGk0n1JI-VwI7m_r7VhDEGxVBSAF24ke8",
  authDomain: "nitenite2.firebaseapp.com",
  projectId: "nitenite2",
  storageBucket: "nitenite2.appspot.com",
  messagingSenderId: "644366877510",
  appId: "1:644366877510:web:4f98cd9adb2ebce3ab1b47",
  measurementId: "G-N4KPN7LX8R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app)
export const auth = getAuth(app)
export default app