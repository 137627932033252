import React from 'react'

const SplashScreen = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <img className="w-[calc(1/6*100vmin)]" src={process.env.PUBLIC_URL + "icons/logo.png"} alt="nitenite logo" />
    </div>
  )
}

export default SplashScreen