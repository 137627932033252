import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {UserAuth} from "../context/AuthContext"


const Login = (props) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [err, setErr] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const {login} = UserAuth()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErr("")
    setIsLoading(true)
    try{
      await login(email, password)
    }catch(e){
      setErr(e.message)
      console.log(e.message)
    }
    finally{
      setIsLoading(false)
    }
  }

  return (
    <div className="max-w-lg mx-auto text-center flex flex-col">
      <div className="flex flex-col justify-center rounded-md my-auto">
        <h1 className="my-4 text-xl">Welcome back</h1>
        <form onSubmit={handleSubmit}
        className="w-2/3 mx-auto text-left text-xl flex flex-col items-center">
          <label htmlFor="email" className="flex flex-col gap-y-4 my-2 w-full">
            {/* <p>Email</p> */}
            <input
              type="email"
              name="email"
              id="email"
              className="border-2 rounded-md p-1 text-base disabled:bg-gray-200"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isLoading}
            />
          </label>
          <label
            htmlFor="password"
            className="flex flex-col gap-y-4 my-2 w-full"
          >
            {/* <p>Password</p> */}
            <input
              type="password"
              name="password"
              id="password"
              className="border-2 rounded-md p-1 text-base disabled:bg-gray-200"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={isLoading}
            />
          </label>
          <Button
            type="submit"
            className="text-white rounded-md text-center !bg-[#3C01E6] !border-none w-full mt-3 !font-medium"
            
            disabled={isLoading}
          >
            Log in
          </Button>
          { err !== "" && (
            <Alert variant="danger">{err}</Alert>
          )
          }
        </form>
        <div className="flex justify-center gap-x-2 my-4">
          <p>Need an account?</p>
          <Link
            to="/signup"
            className="underline text-sky-900"
            onClick={(e) => {
              if (props.showSignup) {
                props.showSignup(e);
              }
            }}
          >
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
