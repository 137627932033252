import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { addDoc, onSnapshot, serverTimestamp } from "firebase/firestore";
import { CreateIcon } from "./icons";
import Generating from "./Generating";

const Create = ({ setSelectedStory, collectionPath, isCreatingStory, setIsCreatingStory, credits, showProducts }) => {
  const promptMoral = useRef(null);
  const promptCharacter = useRef(null);
  const promptStyle = useRef(null);
  const promptAge = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreatingStory(true);
    if (credits <= 0) {
      console.log("insufficient credits");
      return;
    }
    try {
      console.log("Generating story...");
      const inputStyle = promptStyle.current.value !== "" ? promptStyle.current.value : "Ben Clanton"
      const newStoryReference = await addDoc(collectionPath, {
        promptMoral: promptMoral.current.value,
        promptCharacter: promptCharacter.current.value,
        promptStyle: inputStyle,
        promptAge: promptAge.current.value,
        promptString: `Write a fairytale about a ${promptCharacter.current.value}. The story should teach a lesson about ${promptMoral.current.value}. Do it in the style of ${promptStyle.current.value}. The target audience is a ${promptAge.current.value} year old. Also, include a title for the story enclosed like this [title]`,
        createdAt: serverTimestamp(),
        story: "",
      });
      const unsub = onSnapshot(newStoryReference, (story) => {
        if (story.data().story !== "") {
          setIsCreatingStory(false);
          unsub();
          console.log("Story generated!");
          setSelectedStory({ id: story.id, data: story.data() });
        }
      });
    } catch (err) {
      console.warn("Error submitting prompt", err.message);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2 py-6 mx-1 px-4 align-middle">
      {isCreatingStory ? (
        <Generating />
      ) : (
        <>
          <div className=" mt-2 mb-2" style={{ display: "grid", gridTemplateColumns: `32px 1fr`, gap: "12px" }}>
            <CreateIcon size={32}></CreateIcon>
            <h1 className="text-xl font-bold w-full">Create Story</h1>
          </div>
          <div className="flex flex-col gap-2 max-w-xs">
            <label htmlFor="moral">
              <p className="mb-2 text-sm">Lesson you want to teach *</p>
              <input
                id="moral"
                className="w-full border rounded-md mb-3 p-1.5 text-sm"
                type="text"
                ref={promptMoral}
                required
                disabled={credits <= 0}
                placeholder="e.g. saying thanks, no lying, etc"
              ></input>
            </label>
            <label htmlFor="char">
              <p className="mb-2 text-sm">Main character *</p>
              <input
                id="char"
                className="w-full border rounded-md mb-3 p-1.5 text-sm"
                type="text"
                ref={promptCharacter}
                required
                disabled={credits <= 0}
                placeholder="e.g. a dragon, a pand, a ladybug"
              ></input>
            </label>
            <label htmlFor="style">
              <p className="mb-2 text-sm">Style</p>
              <input
                id="style"
                className="w-full border rounded-md mb-3 p-1.5 text-sm"
                type="text"
                ref={promptStyle}
                disabled={credits <= 0}
                placeholder="e.g. , Ben Clanton, Dr. Seuss, Roal Dahl"
              ></input>
            </label>
            <label htmlFor="age">
              <p className="mb-2 text-sm">Kid's age *</p>
              <input
                id="age"
                className="w-full border rounded-md mb-3 p-1.5 text-sm"
                type="number"
                ref={promptAge}
                required
                disabled={credits <= 0}
                placeholder="e.g. 8, 7, 4"
              ></input>
            </label>
            <Button
              className="px-4 py-2 my-3 !bg-[#3C01E6] !border-none"
              type="submit"
              disabled={credits <= 0}
            >
              Create
            </Button>
            <p className="m-0 text-sm">You have {credits} credits to create more stories</p>
            <a
            className="!text-[#3C01E6] text-sm"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                showProducts(true);
              }}
            >
              Get more here!!
            </a>
          </div>
        </>
      )}
    </form>
  );
};

export default Create;
