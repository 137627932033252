import React from "react";
import { Button } from "react-bootstrap";
import "../../styles/cardanim.css"

const SingleStoryCard = ({ selectedStory, setSelectedStory }) => {
  const titleRegex = new RegExp(/\[(.*?)\]/, "i")
  const title = selectedStory.data.story.match(titleRegex) ? selectedStory.data.story.match(titleRegex)[0] : selectedStory.data.promptMoral
  return (
    <div className=" anim-card absolute inset-0 bg-white flex justify-start items-start mt-14" style={{height: "calc(100% - 96px - 48px)"}}>
      <div className=" flex flex-col justify-start py-12 w-full max-w-3xl shadow-md h-full mx-auto">
        <div className="px-6 flex justify-between gap-6">
          <h1 className="text-2xl capitalize">{title.replace("[", "").replace("]", "")}</h1>
          <Button variant="outline-primary" className="my-auto max-w-fit"
          onClick={(e) => setSelectedStory(null)}>Close</Button>
        </div>
        <div className="flex flex-col justify-center items-start px-6 my-6 ">
          <h1 className="font-medium text-lg my-0 text-slate-800 capitalize">{selectedStory.data.promptMoral}</h1>
          <p className="whitespace-pre-line mb-40 mt-4 text-slate-900">{selectedStory.data.story.replace(title, "").replace(/^\s+/, "")}</p>
        </div>
      </div>
    </div>
  );
};

export default SingleStoryCard;
