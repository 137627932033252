import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { setDoc, doc, serverTimestamp, updateDoc, increment } from "firebase/firestore";
import { db } from "../../firebase";
import { AccountIcon } from "./icons";

const Account = ({ credits, showProducts }) => {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await logout();
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (err) {
      console.log(err.message);
    }
  };

  const initialDocTest = async (e) => {
    e.preventDefault();
    try {
      await setDoc(
        doc(db, "users", user.uid),
        {
          email: user.email,
          createdAt: serverTimestamp(),
        },
        { merge: true }
      );
      await setDoc(
        doc(db, "credits", user.uid),
        {
          email: user.email,
          createdAt: serverTimestamp(),
          credits: 5,
        },
        { merge: true }
      );
      console.log("Doc created");
    } catch (err) {
      console.warn("Error creating doc: ", err.message);
    }
  };

  const incrementCredits = async (e) => {
    e.preventDefault();
    await updateDoc(doc(db, "credits", user.uid), {
      credits: increment(1),
    });
  };
  const decreaseCredits = async (e) => {
    e.preventDefault();
    await updateDoc(doc(db, "credits", user.uid), {
      credits: increment(-1),
    });
  };

  return (
    //<div className="max-w-5xl w-full mx-auto py-4 px-4 text-center h-[100vb] flex flex-col overflow-y-scroll">
    <div className="flex flex-col py-6 pb-20 px-4 mx-1 align-middle">
      <div className="mt-2 mb-2" style={{ display: "grid", gridTemplateColumns: `32px 1fr`, gap: "12px" }}>
        <AccountIcon size={32}></AccountIcon>
        <h1 className="text-xl font-bold w-full">Account</h1>
      </div>
      <div className="mt-3 flex flex-col">
        <p className="m-0">
          Email:
          {user.email && <span> {user.email}</span>}
        </p>
        <p className="m-0 mt-2" >
          <a href="#" className="!text-[#3C01E6]">
            Update password
          </a>
        </p>
        <div className="flex flex-col my-4">
          <p className="whitespace-pre-wrap inline-block mb-2">You have {credits} credits left</p>
          <Button className="max-w-xs w-full !bg-[#3C01E6] !border-none" onClick={(e) => showProducts(true)}>
            Get more here!!
          </Button>
        </div>
        <p className="m-0">Contact us: <i>hello@getnitenite.com</i></p>
        <a
          className="inline-block !text-[#3C01E6] mt-3"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleLogout(e);
          }}
        >
          Log out
        </a>
        <p className="text-sm text-slate-500 mt-4">Copyright 2023 - NiteNite - Terms of Use and Privacy Policy</p>
      </div>
      {/* <div className="flex flex-col border my-3">
        <p>Simulate</p>
        <p>Test increment or decrease credits</p>
        <div className="flex justify-center gap-10 my-3">
          <Button
            // className="max-w-md mx-auto px-4 py-2"
            onClick={(e) => decreaseCredits(e)}
          >
            -
          </Button>
          <Button
            // className="max-w-md mx-auto px-4 py-2"
            onClick={(e) => incrementCredits(e)}
          >
            +
          </Button>
        </div>
        <Button
          size="lg"
          className="max-w-md mx-auto px-4 py-2"
          onClick={(e) => initialDocTest(e)}
        >
          On Create User
        </Button>
      </div> */}
    </div>
    //</div>
  );
};

export default Account;
