import React from "react";
import { Nav } from "react-bootstrap";
import { AccountIcon, CreateIcon, HowToIcon, LibraryIcon } from "./icons";
import "styles/icons.css";

const NavigationButtons = ({ setCurrentPage, currentPage, setSelectedStory, showProducts }) => {
  const setPage = (e) => {
    setSelectedStory(null);
    showProducts(false);
    setCurrentPage(e);
  };
  return (
    <Nav
      className="mx-auto px-3 justify-center fixed bottom-0 inset-x-0 h-24 box-border bg-[#D9D9D9]"
      // style={{ boxShadow: "0 -2px 6px -1px rgb(0 0 0 / 0.15)" }}
      onSelect={(e) => setPage(e)}
    >
      <div className="w-full max-w-2xl grid grid-cols-4 ">
        <Nav.Item className="h-full flex flex-col justify-center" >
          <Nav.Link
            className={"!flex flex-col items-center btn-relative rounded-sm text-center bg-[#D9D9D9] !text-[#3C01E6] ".concat(
              currentPage === "create" ? "" : "hover:bg-slate-100"
            )}
            eventKey={"create"}
            active={currentPage === "create"}
          >
            <CreateIcon />
            Create
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="h-full flex flex-col justify-center">
          <Nav.Link
            className={"!flex flex-col items-center btn-relative rounded-sm text-center bg-[#D9D9D9] !text-[#3C01E6] ".concat(
              currentPage === "library" ? "" : "hover:bg-slate-100"
            )}
            eventKey={"library"}
            active={currentPage === "library"}
          >
            <LibraryIcon />
            Library
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="h-full flex flex-col justify-center">
          <Nav.Link
            className={"!flex flex-col items-center btn-relative whitespace-nowrap rounded-sm text-center bg-[#D9D9D9] !text-[#3C01E6] ".concat(
              currentPage === "howto" ? "" : "hover:bg-slate-100"
            )}
            eventKey={"howto"}
            active={currentPage === "howto"}
          >
            <HowToIcon />
            How to
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="h-full flex flex-col justify-center">
          <Nav.Link
            className={"!flex flex-col items-center btn-relative rounded-sm text-center bg-[#D9D9D9] !text-[#3C01E6] ".concat(
              currentPage === "account" ? "" : "hover:bg-slate-100"
            )}
            eventKey={"account"}
            active={currentPage === "account"}
          >
            <AccountIcon />
            Account
          </Nav.Link>
        </Nav.Item>
      </div>
    </Nav>
  );
};

export default NavigationButtons;
