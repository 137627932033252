import Main from "components/Main";
import Landing from "components/Landing";
import { Routes, Route, Navigate } from "react-router-dom";
import React, { Suspense } from "react";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "components/ProtectedRoute";
import Home from "components/Home";

function App() {
  return (
    <div>
      <Suspense fallback={<p>LOADING</p>}>
        <AuthContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Navigate to={"/"} replace />} />
            {/* <Route path="/auth" element={<div>Verify Email</div>} /> */}
          </Routes>
        </AuthContextProvider>
      </Suspense>
    </div>
  );
}

export default App;
