import React, { useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const Signup = (props) => {
  const email = useRef(null);
  const password = useRef(null);
  const repeatPassword = useRef(null);
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { createUser } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErr("");
    setIsLoading(true);
    try {
      if (password.current.value !== repeatPassword.current.value) {
        throw Object.assign(new Error("Passwords must match!"), { code: 402 });
      }
      await createUser(email.current.value, password.current.value);
    } catch (e) {
      setErr(e.message);
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto text-center flex flex-col">
      <div className="flex flex-col justify-center rounded-md my-auto">
        <h1 className="my-4 text-xl">Create your account</h1>
        <form onSubmit={handleSubmit} className="w-2/3 mx-auto text-left text-xl flex flex-col items-center">
          <label htmlFor="email" className="flex flex-col gap-y-4 my-2 w-full">
            {/* <p>Email</p> */}
            <input
              type="email"
              name="email"
              id="email"
              className="border-2 rounded-md p-1 text-base disabled:bg-gray-200"
              placeholder="Email"
              ref={email}
              required
              disabled={isLoading}
            />
          </label>
          <label htmlFor="password" className="flex flex-col gap-y-4 my-2 w-full">
            {/* <p>Password</p> */}
            <input
              type="password"
              name="password"
              id="password"
              className="border-2 rounded-md p-1 text-base disabled:bg-gray-200"
              placeholder="Password"
              ref={password}
              required
              disabled={isLoading}
            />
          </label>
          <label htmlFor="repeatpassword" className="flex flex-col gap-y-4 my-2 w-full">
            {/* <p>Password</p> */}
            <input
              type="password"
              name="repeatpassword"
              id="repeatpassword"
              className="border-2 rounded-md p-1 text-base disabled:bg-gray-200"
              placeholder="Repeat password"
              ref={repeatPassword}
              required
              disabled={isLoading}
            />
          </label>
          <Button
            type="submit"
            className="text-white rounded-md mb-3 text-center !bg-[#3C01E6] !border-none w-full mt-3 !font-medium"
            disabled={isLoading}
          >
            Sign up
          </Button>
          {err !== "" && (
            <Alert variant="danger" className="!text-lg">
              {err}
            </Alert>
          )}
        </form>
        <div className="flex justify-center gap-x-2 my-4">
          <p>Already have an account?</p>
          <Link
            to="/login"
            className="underline text-sky-900"
            onClick={(e) => {
              if (props.showLogin) {
                props.showLogin(e);
              }
            }}
          >
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
